
import useAxios from "../utils/axiosHook";
 
class AuthRepository {
  constructor(axios) {
    this.Axios = axios; 
  }
  async login(userName, password) {
    return await this.Axios.post(
      `${process.env.REACT_APP_BASEPATH}/login`,
      { userName, password }
    );
  }

  async saveProductEnquiry(bodyData) {
    return await this.Axios.post(
      `${process.env.REACT_APP_BASEPATH}/saveProductEnquiry`, bodyData);
  }

  async resetPassword(_id, role, schoolId, bodyData) {
    return await this.Axios.put(
      `${process.env.REACT_APP_BASEPATH}/resetPassword?_id=${_id}&role=${role}${schoolId ? `&schoolId=${schoolId}` : ``
      }`,
      bodyData
    );
  }

}
function useAuthRepository() {
  const { getInstance } = useAxios();

  return new AuthRepository(getInstance());
}
export default useAuthRepository;

