import { lastUpdated } from "../../helper/redirection";
import Footer from "../footer";
import Header from "../Header";

function TermsOfService() {
  return (
    <div className="LandingBody">
      <Header />
      <div className="container tailwnd-container pb-4"       
          style={{ backgroundColor: "#D3FEEA" }}>
            <div style={{ backgroundColor: "white" }} className="pt-2 pb-2 px-2">
        <h1 className="tailwnd-h2">Terms of service</h1>
        <h5 className="tailwnd-p tailwnd-f-18 pt-2">Last Updated: {lastUpdated}</h5>
        <section className="pt-5 ps-2">
          <h1 className="tailwnd-h1">1. Introduction</h1>
          <p className="tailwnd-p tailwnd-f-18 ms-4 pt-2">
            <strong>1.1.</strong> These Terms of Service govern your use of our websites at www.tailwnd.com, and the Tailwnd Application (collectively “the Tailwnd Service”, “the Service” or “Tailwnd”). By signing this agreement, you agree to be bound by our Terms of Service (our “Terms”).
          </p>
          <p className="tailwnd-p tailwnd-f-18 ms-4"><strong>1.2.</strong> Occasionally Service Provider* may, at its discretion, make changes to the Service* and the Order Form. Upon such material changes to the Agreement or Services, Service Provider shall notify the Subscriber*. By continuing to use the Service or entitle the Users to continue using the Service after changes are made, Subscriber is expressing and acknowledging its acceptance of the changes.</p>
          <p className="tailwnd-p tailwnd-f-18 ms-4">*see definitions below</p>
        </section>
        <section className="pt-5 ps-2">
          <h1 className="tailwnd-h1">2. Definitions</h1>
          <p className="tailwnd-p tailwnd-f-18 ms-4 pt-2">
            In this document, unless the context otherwise requires:
          </p> 
          <p className="tailwnd-p tailwnd-f-18 ms-4"><strong>“Agreement”</strong> or <strong>“Order Form”</strong> means this order form, these Terms of Service and any supplementary documents referred to herein (executed in written form online).</p>
          <p className="tailwnd-p tailwnd-f-18 ms-4"><strong>“Confidential Information”</strong> means any information that: (i) if disclosed in writing, is labelled as “confidential” or “proprietary”; (ii) if disclosed orally, is designated confidential at disclosure; or (iii) whether it being written or oral and whether it being designated as confidential or not, by its nature, and/or the circumstances of its disclosure, makes it reasonably likely that it is confidential. Confidential Information may include, but is not limited to, trade secrets, discoveries, ideas, concepts, know-how, techniques, future product plans, designs, specifications, data, computer programs, business activities and operations, customer lists, reports, studies, personnel information, financial information, information disclosed by demonstration of any product and other technical and business information;</p>
          <p className="tailwnd-p tailwnd-f-18 ms-4"><strong>“Effective Date”</strong> means the date the Subscriber and the Service Provider execute the Order Form;</p>
          <p className="tailwnd-p tailwnd-f-18 ms-4"><strong>“Intellectual Property Rights”</strong>means all copyright and related rights, design rights, registered designs, patents, trade and service marks (registered and unregistered), database rights, know-how, rights in confidential information and all other intellectual property rights throughout the world for the full term of the rights concerned;</p>
          <p className="tailwnd-p tailwnd-f-18 ms-4"><strong>“Service”</strong>means access to web portals and mobile applications provided by the Service Provider, including the planning, documentation, evidence collection, assessment, portfolio tools and any other tools or features that may be developed in the future;</p>
          <p className="tailwnd-p tailwnd-f-18 ms-4"><strong>“Service Provider”</strong>means Flow Learning Solutions LLP, operating through its brand Tailwnd.</p>
          <p className="tailwnd-p tailwnd-f-18 ms-4"><strong>“Subscription Period” </strong>means the Service subscription period for a specific Subscriber and its Licensed Users. The Subscription Period is twelve (12) months with automatic renewal every 12 months and will commence on the Effective Date, or where so agreed a longer or shorter time period, as outlined in the subscription service form. Each agreed subscription will have a separate Subscription Period;</p>
          <p className="tailwnd-p tailwnd-f-18 ms-4"><strong>“Terms and conditions of Use” </strong>means the Service Provider’s supplementary document “Tailwnd Terms of Use” that the Subscriber has to accept to use the Service, available at <a href="https://tailwnd.com">https://tailwnd.com</a> as the same may be updated from time to time.</p>
          <p className="tailwnd-p tailwnd-f-18 ms-4"><strong>“User Account Information” </strong>means information requested by the Service Provider on Subscriber’s Users in order to set up and register the Users for Services.</p>
          <p className="tailwnd-p tailwnd-f-18 ms-4"><strong>“Users” </strong>means individual students, teachers, parents and administrators who are authorized by the Subscriber to use the Service, for whom subscriptions to a Service have been procured, and who have been supplied user identifications and passwords by the Subscriber (or by Service Provider at Subscriber’s request).</p>
          <p className="tailwnd-p tailwnd-f-18 ms-4"><strong>“Licensed Users” </strong>means individual students and staff of the Subscriber considered for billing purposes by the Subscription Provider.</p>
          <p className="tailwnd-p tailwnd-f-18 ms-4"><strong>“Add-on services” </strong>means any one or a combination of the services provided by the Service Provider outside the scope of this Order Form.</p>
        </section>
        <section className="pt-5 ps-2">
            <h1 className="tailwnd-h1">3. Supply of Services</h1>
            <p className="tailwnd-p tailwnd-f-18 ms-4 pt-2"><strong>3.1.</strong> Service Provider hereby undertakes to supply to Subscriber the subscribed Service on the terms outlined in the Order Form.</p>
            <p className="tailwnd-p tailwnd-f-18 ms-4"><strong>3.2.</strong> By subscribing to Services, the signatory warrants and represents that it is authorized to bind the Subscriber to this Agreement. Subscriber further warrants and represents that; (i) it is registered in its territory and will only grant User access to the Services in that territory; (ii) it complies with and will comply with the subscription requirements set out in the Order Form; (iii) any registration information that it submits to the Service Provider is true, accurate and complete, and it agrees to keep it that way at all times; (iv) it is authorized to grant all permissions and licenses provided in this Agreement to its Users; (v) it shall comply with any laws and regulations that apply to its use of the Services (in particular local regulatory regimes on privacy and data protection); and (vi) it shall not use the Services for any other use than set forth herein and shall not allow any third party to do so.</p>
            <p className="tailwnd-p tailwnd-f-18 ms-4"><strong>3.3.</strong> Use of the Services requires User registration with Service Provider. Such registration is made by the Service Provider upon receiving the requested User Account Information from the Subscriber.</p>
            <p className="tailwnd-p tailwnd-f-18 ms-4"><strong>3.4.</strong> Subscriber confirms that it will accept and comply with Service Provider’s Terms and conditions of Use and other relevant terms, and that Users consent to the processing of any personal data as set forth in this Agreement.</p>
            <p className="tailwnd-p tailwnd-f-18 ms-4"><strong>3.5.</strong> Subscriber confirms that it is responsible that its Users at all times comply with the terms of the Agreement and adhere to Service Provider’s requirements and guidelines in relation to the use of the Service, as designated by Service Provider from time to time, including applicable Terms and conditions of Use and instructions.</p>
            <p className="tailwnd-p tailwnd-f-18 ms-4"><strong>3.6.</strong> Subscriber or registered Users are responsible for all hardware, communication networks and other equipment necessary for use of Services, and the due installation thereof. Subscriber is solely responsible for all activities conducted by Users, through each of any User’s logins. Subscriber is not allowed to engage in service bureau use, outsourcing, renting, reselling, sublicensing, concurrent use of a single User login, or time-sharing of the Service.</p>
            <p className="tailwnd-p tailwnd-f-18 ms-4"><strong>3.7.</strong> Subscriber shall not and shall not permit Users to; (i) copy, translate, create a derivative work of, reverse engineer, reverse assemble, disassemble, or decompile the Service or any part thereof or otherwise attempt to discover any source code or modify the Service in any manner or form; (ii) use the Service in a manner that is contrary to applicable law or in violation of any third party rights of privacy or Service Provider’s Intellectual Property Rights; (iii) use or knowingly permit the use of any security testing tools in order to probe, scan or attempt to penetrate or ascertain the security of the Service; (iv) access the Service by any means other than through the interfaces that are provided by Service Provider; or (v) use or allow Users or third parties to use the Services in any unauthorized manner or in breach of the Terms of Use.</p>
            <p className="tailwnd-p tailwnd-f-18 ms-4"><strong>3.8.</strong> The Service Provider shall; (i) authorize registered Users access to and assign unique passwords and usernames to Users; (ii) grant Users access to the portals and mobile applications relevant to that User in accordance with the terms and conditions of this Agreement; and (iii) endeavour that the use of the Service is secure, error-free, or that the Service will meet the subscribed requirements, or that errors in the Service are reasonably resolved and that the overall system hosting the Service is free of viruses or other harmful components. Service Provider will use its reasonable endeavours to resolve any issues related to the Service, as part of its technical support obligations.</p>
            <p className="tailwnd-p tailwnd-f-18 ms-4"><strong>3.9.</strong> Service Provider reserves the right to make such changes to the features and functionality of the Service, systems and interfaces as Service Provider sees fit at its sole discretion. Notwithstanding the aforesaid, Service Provider will notify the Subscriber, or where relevant its Users, of any material changes in advance where reasonably practicable and where such prior notice would not adversely affect Service Provider’s commercial interests.</p>
            <p className="tailwnd-p tailwnd-f-18 ms-4"><strong>3.10.</strong> To the fullest extent possible under applicable law, Service Provider does not give any warranty, express or implied, as to the quality, content and availability or fitness for a specific purpose of the Service, or the non- infringement of any third party Intellectual Property Rights.</p>
            <p className="tailwnd-p tailwnd-f-18 ms-4"><strong>3.11.</strong> The Subscriber will take all actions as may be required to give effect to such instruction/direction and represents and warrants that such instruction/ direction will not result in a breach of any applicable law or contract (which is applicable to the Subscriber). The Service Provider agrees to utilize any data (including personal data) processed pursuant to this instruction/ direction solely for the purposes as notified.</p>
        </section>
        <section className="pt-5 ps-2">
            <h1 className="tailwnd-h1">4. Fees and Payment</h1>
            <p className="tailwnd-p tailwnd-f-18 ms-4 pt-2"><strong>4.1.</strong> The prices and conditions for the services are subject for a review from time to time, and the Service Provider reserves the right to propose adjustments to the prices. Any proposed adjustments must be communicated at least two (2) months prior to the expiration of a Subscription Period. The nominal price increase for the subscribed Service will be approximately 5% annually, accounting for inflation and continuous product improvements. Unless the Subscriber cancels the subscription in accordance with the terms outlined herein, the adjusted prices for the subscribed Service will apply upon renewal for each subsequent Subscription Period.</p>
            <p className="tailwnd-p tailwnd-f-18 ms-4"><strong>4.2.</strong> Charges for Add-on services, if any availed by the School, will be billed as and when services are provided to the School.</p>
            <p className="tailwnd-p tailwnd-f-18 ms-4"><strong>4.3.</strong> Unless otherwise agreed in the Agreement, the Service Provider will invoice the Subscriber for the Full Subscription Period as specified in the Order Form. Invoiced Amounts are in the currency set out in the Order Form, and exclude taxes, levies or duties of any kind including, but not limited to, value-added, General Sales, sales use or withholding taxes.</p>
            <p className="tailwnd-p tailwnd-f-18 ms-4"><strong>4.4.</strong> All invoices are payable upon presentation and should be paid no later than fifteen (15) calendar days either from the start of subscription period or upon automatic renewal as the case may be, unless otherwise agreed in the Agreement. All amounts owed hereunder, not paid when due, will be subject to penalty interest at the rate 9% p.a. on the amount due as well as compensation for costs for recovery of late payment. Upon late payment, Service Provider, at its own discretion, may temporarily restrict Licensed Users access to the Service, until such time that full payment is received.</p>
            <p className="tailwnd-p tailwnd-f-18 ms-4"><strong>4.5.</strong> In case the actual number of Licensed Users is different from the number of Licensed Users mentioned in the order form, an adjustment will be made in the invoice raised in the next billing cycle.</p>
        </section>
        <section className="pt-5 ps-2">
            <h1 className="tailwnd-h1">5. Subscription and Cancellation</h1>
            <p className="tailwnd-p tailwnd-f-18 ms-4 pt-2"><strong>5.1.</strong> Unless otherwise agreed in the Agreement, the Subscription Period shall be twelve (12) months commencing on the Effective Date. Unless the Subscriber cancels the subscription, at least 60 days prior to the contract renewal date, the subscription is automatically renewed for a subsequent successive Subscription Period.</p>
            <p className="tailwnd-p tailwnd-f-18 ms-4"><strong>5.2.</strong> The subscription is binding during the full duration of the Subscription Period. The subscriber can choose to terminate the subscription by submitting a termination notice anytime during the validity of the subscription.</p>
            <p className="tailwnd-p tailwnd-f-18 ms-4"><strong>5.3.</strong> Pursuant to the provisions herein, any Subscriber requests for modification of the Service’s scope or cancellation of the Subscription Period must be submitted in writing to the designated contact person at least 60 days prior to the subscription renewal date as mentioned in the order form. The Service Provider will use reasonable efforts to accommodate modification requests but is not obligated to do so.</p>
            <p className="tailwnd-p tailwnd-f-18 ms-4"><strong>5.4.</strong> Upon the termination of this Agreement for any reason any sum owing or due to the Service Provider shall be immediately payable and the rights of the Subscriber and Licensed Users herein shall be immediately cancelled.</p>
        </section>
        <section className="pt-5 ps-2">
            <h1 className="tailwnd-h1">6. Intellectual Property Rights</h1>
            <p className="tailwnd-p tailwnd-f-18 ms-4 pt-2"><strong>6.1.</strong> Service Provider and its licensors, as applicable, shall remain the exclusive owner of all Intellectual Property Rights in the Service (including the underlying technology, software and content). Subscriber may not remove any legends or statements in the Service or any materials provided therewith regarding Service Provider’s and its licensors’ proprietary rights.</p>
            <p className="tailwnd-p tailwnd-f-18 ms-4"><strong>6.2.</strong> Service Provider’s trademarks, service marks, trade names, logos, domain names, and any other features of the Service are the sole property of Service Provider and its licensors. The license granted to Subscriber and its Users herein does not grant any rights to use Intellectual Property Rights or any other features of the Service, whether for commercial or non-commercial use.</p>
            <p className="tailwnd-p tailwnd-f-18 ms-4"><strong>6.3.</strong> It follows from the Service Provider’s Terms and conditions of Use that Subscriber and/or its Users shall become the owner of any content that they create on the Tailwnd portals. The content created on journals and portfolios shall belong to the parents and the children (wherever they are above the legal age).</p>
        </section>
        <section className="pt-5 ps-2">
            <h1 className="tailwnd-h1">7. Confidentiality and Privacy</h1>
            <p className="tailwnd-p tailwnd-f-18 ms-4 pt-2"><strong>7.1.</strong> Any and all Confidential Information exchanged between the Service Provider, Subscriber and its Users shall be kept strictly confidential and not disclosed to any third party without prior written consent of the owner of the Confidential Information. At all times, Confidential Information shall be treated and stored carefully and appropriately so that the Confidential Information is not inadvertently made available to any third party or otherwise disclosed in breach of this Agreement.</p>
            <p className="tailwnd-p tailwnd-f-18 ms-4"><strong>7.2.</strong> Notwithstanding the aforesaid, this Agreement shall not prohibit the disclosure of Confidential Information to the extent that such disclosure is permitted or required by law, regulation or order of a court or other governmental authority. Furthermore, the Service Provider may disclose Confidential Information on a need to know basis to its contractors and service providers who have executed written agreements requiring them to maintain such information in strict confidence and use it only to facilitate the performance of their services in connection with the Services.</p>
            <p className="tailwnd-p tailwnd-f-18 ms-4"><strong>7.3.</strong> Subscriber and its Users may not copy, make transcriptions or recordings or in any other way reproduce or duplicate any document or other medium containing Confidential Information or Intellectual Property Rights, without Service Provider’s written consent.</p>
            <p className="tailwnd-p tailwnd-f-18 ms-4"><strong>7.4.</strong> Service Provider will comply with applicable Data Protection Regulation as well as with its own, at each time applicable, Privacy Policy in relation to any Subscriber or User personal data that it may collect and process for the purposes of supplying the Service. Service Provider will process any such personal data in accordance with Service Provider’s Privacy Policy, available online at <a href="https://tailwnd.com">www.tailwnd.com</a></p>
        </section>
        <section className="pt-5 ps-2">
            <h1 className="tailwnd-h1">8. Limitation of Liability</h1>
            <p className="tailwnd-p tailwnd-f-18 ms-4 pt-2"><strong>8.1.</strong> Service Provider shall not be liable for any loss of profits, business, goodwill, revenue, sales, or data, or for any indirect, consequential, incidental or special loss or damages of any kind under or in connection with this Agreement, save where such loss or damage is due to Service Provider’s gross negligence or wilful violation of the terms of this Agreement. Notwithstanding aforesaid, the Service Provider’s maximum liability due to any person, firm or corporation whatsoever arising out of or in the connection with any license, use or other employment of the Service, whether such liability arises from any claim based on breach or repudiation of contract, breach of warranty, negligence, tort, statutory duty, or otherwise, shall in no case exceed the equivalent of three (3) months in subscription fees for the relevant Subscription Period. Subscriber shall not have the right to terminate this Agreement unless such loss or damage is due to Service Provider’s gross negligence or wilful violation of the terms of this Agreement.</p>
            <p className="tailwnd-p tailwnd-f-18 ms-4"><strong>8.2.</strong> Service Provider shall not be liable for damages for any delay or default in performance of its undertakings of obligations under this Agreement, if such delay or default is caused by force majeure, including but without limitation thereto, wars, insurrections, fires, passing of laws or any governmental order, regulation or ruling, or any other act(s) beyond the reasonable control of Service Provider, and Subscriber shall not have the right to terminate this Agreement unless such delay or default in performance is caused by force majeure for a period of more than six (6) months. During the force majeure event, Service Provider will use all reasonable efforts to avoid, reduce or eliminate the force majeure event’s prevention, restriction or delay of the performance of its obligations under this Agreement.</p>
            <p className="tailwnd-p tailwnd-f-18 ms-4"><strong>8.3.</strong> The Subscriber agrees to indemnify and defend and hold harmless the Service Provider against any claims arising out of any actions taken by the Service Provider on instruction or direction by the Subscriber.</p>
        </section>
        <section className="pt-5 ps-2">
            <h1 className="tailwnd-h1">9. Miscellaneous</h1>
            <p className="tailwnd-p tailwnd-f-18 ms-4 pt-2"><strong>9.1.</strong> Any notice or other communication to be given or served under or in connection with this Agreement shall be in writing and shall be sent by e-mail to the other party’s contact person set forth in the order form.</p>
            <p className="tailwnd-p tailwnd-f-18 ms-4"><strong>9.2.</strong> Subscriber may not assign, mortgage, charge any of its rights or sub-contract or otherwise delegate any of its obligations under this Agreement, except with the written consent of the Service Provider.</p>
            <p className="tailwnd-p tailwnd-f-18 ms-4"><strong>9.3.</strong> This Agreement constitutes the whole of the agreement between the parties and supersedes any previous agreement and no modification of this Agreement shall be effective unless it is made in writing and executed by or on behalf of the parties unless otherwise is set forth in this Agreement.</p>
            <p className="tailwnd-p tailwnd-f-18 ms-4"><strong>9.4.</strong> No failure or delay by any party in exercising any of its rights under this Agreement shall be deemed to be a waiver of that right, or otherwise prejudice, affect or restrict the rights or remedies of that party in relation to the other party, and no waiver by any party of a breach of any provision of this Agreement shall be deemed to be a waiver of any subsequent breach of the same or any other provision of this Agreement.</p>
            <p className="tailwnd-p tailwnd-f-18 ms-4"><strong>9.5.</strong> All provisions of this Agreement that can be reasonably interpreted as surviving upon the full performance, expiry, termination, cancellation or voidance of the Agreement (in particular provisions on intellectual property rights and confidentiality) shall survive said performance, expiry, termination, cancellation or voidance.</p>
        </section>
        <section className="pt-5 ps-2">
            <h1 className="tailwnd-h1">10. Applicable Law and Dispute Resolution</h1>
            <p className="tailwnd-p tailwnd-f-18 ms-4 pt-2">Any dispute, controversy or claim arising out of or in connection with this Agreement, or the breach, termination or invalidity thereof, shall be finally settled by the Courts of Ghaziabad, India.</p>
        </section>
        </div>
      </div>
      <Footer/>
    </div>
  );
}

export default TermsOfService;
