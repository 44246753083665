import { lastUpdated } from "../../helper/redirection";
import Footer from "../footer";
import Header from "../Header";

function TermsToUse() {
  return (
    <div className="LandingBody">
      <Header />
      <div className="container tailwnd-container  pb-4"           style={{ backgroundColor: "#F6EDFF" }}>
        <div style={{ backgroundColor: "white" }} className="pt-2 pb-2 px-2">
        <h1 className="tailwnd-h2">Terms to Use</h1>
        <h5 className="tailwnd-p tailwnd-f-18 pt-2">Last Updated: {lastUpdated}</h5>
        <section className="pt-5 ps-2">
          <h1 className="tailwnd-h1">1.Introduction</h1>
          <p className="tailwnd-p tailwnd-f-18 ms-4 pt-2">
            These Terms of Use, read together with Tailwnd’s Terms of Service
            and Tailwnd’s Privacy Policy govern your use of our websites at
            www.tailwnd.com, and the Tailwnd Application (collectively “the
            Tailwnd Service”, “the Service” or “Tailwnd”). By using our
            services, you agree that you have read, understood and agree by the
            terms contained in this agreement and you agree to be bound by our
            Terms of Use (our “Terms”). If you do not unconditionally agree to
            the Terms contained in this agreement, please refrain from using our
            services.
          </p>
        </section>
        <section className="pt-3 ps-2">
          <h1 className="tailwnd-h1">2. Declaration from the User</h1>
          <p className="tailwnd-p tailwnd-f-18 ms-4 pt-2">
            User hereby undertakes that they are qualified to enter into a
            binding contract with the Service Provider. In case the user is a
            minor, the user undertakes that their parent or legal guardian has
            read and agrees to this Agreement.
          </p>
        </section>
        <section className="pt-3 ps-2">
          <h1 className="tailwnd-h1">3. Supply of Services</h1>
          <p className="tailwnd-p tailwnd-f-18 ms-4 pt-2">
            The Service Provider, hereby, gives you permission to use the
            Service subject to your compliance with the terms and conditions
            laid out in this Agreement. The Service Provider reserves the right
            to change the Terms of this Agreement or the nature of the Service
            at its sole discretion.
          </p>
          <p className="tailwnd-p tailwnd-f-18 ms-4 pt-2">
            In case of any changes in the terms of this agreement, which may
            materially affect your rights, the Service Provider will send a
            notification 15 days before these changes take place. By continuing
            to use the Service or entitling the Users to continue using the
            Service after changes are made, the Subscriber is expressing and
            acknowledging its acceptance of the changes.
          </p>
        </section>
        <section className="pt-3 ps-2">
          <h1 className="tailwnd-h1">4. Terms of Use for Teachers and School Administrators</h1>
          <ul className="tailwnd-p tailwnd-f-18 ms-3 pt-2 tailwnd-list">
            <li>
              You understand that you are accessing the Services on behalf of
              the Subscriber (your school).
            </li>
            <li>
              You understand that DPDPA (Digital Personal Data Protection Act;
              for schools in India) require parental consent for the collection
              of personally identifiable information from underage children. As
              a third party operator, Tailwnd relies on School Consent for all
              underage children under DPDPA. If you are a school or teacher and
              you would like to obtain direct parental consent from the parent,
              Tailwnd has provided a consent form which can be downloaded here
            </li>
            <li>
              You will use the Services responsibly, and for academic purposes
              only and will take reasonable steps to ensure that the students in
              your class also use the Services responsibly.
            </li>
            <li>
              You will respect the privacy of the children and only share the
              class journal content with relevant parents and families.
            </li>
            <li>
              You undertake that any students that you add to your grade belong
              to your grade.
            </li>
            <li>
              You undertake that you are the owner of or have all required
              rights, licenses, consents, and permissions to use any copyrighted
              work, including literary works, visual works, musical works, sound
              recordings, audio-visual works, or other work or any combination
              of the foregoing, of any content that you upload on the Service
              and grant the rights granted herein to the Service Provider.
            </li>
          </ul>
        </section>
        <section className="pt-3 ps-2">
          <h1 className="tailwnd-h1">5. Terms of Use for Students</h1>
          <ul className="tailwnd-p tailwnd-f-18 ms-3 pt-2 tailwnd-list">
            <li>
              You must sign up for the Service using the code provided to you by
              your teacher. You will only use the Services if a code to sign up
              is provided to you by your teacher.
            </li>
            <li>
              In India, the Digital Personal Data Protection Act, 2023 (DPDPA)
              mandates obtaining verifiable parental consent if you are under
              the age of 18.
            </li>
            <li>
              You will use the Services responsibly and respect the rights and
              privacy of other Users.
            </li>
            <li>
              The Service Provider does not encourage you to share your
              personally identifiable information (name, phone number, email,
              photos, videos) publicly and advises you to refrain from sharing
              it publicly.
            </li>
            <li>
              You consent to the Service Provider storing and accessing your
              “Educational Records”.
            </li>
          </ul>
        </section>
        <section className="pt-3 ps-2">
          <h1 className="tailwnd-h1">6. Terms of Use for Parents</h1>
          <ul className="tailwnd-p tailwnd-f-18 ms-3 pt-2 tailwnd-list">
            <li>
              You consent to the usage of the Services provided by the Service
              Provider by your child. You understand that the Service Provider
              may collect Personally Identifiable Information of the child for
              this.
            </li>
            <li>
              If your child is below the legal age (18 years in India), and is
              using the Service without your consent, please send an email to
              the Service Provider at info@tailwnd.com with relevant details.
            </li>
            <li>
              You understand and agree that the Service Provider and or teachers
              using the Service may send you messages, alerts, and other
              communications through the Service, including via email, web
              alert, text messages, and other similar means, in accordance with
              the preferences you set through the Services, and you agree to
              accept such messages.
            </li>
            <li>
              You consent to the Service Provider storing and accessing the
              “Educational Records” of your child.
            </li>
          </ul>
        </section>
        <section className="pt-3 ps-2">
          <h1 className="tailwnd-h1">7. General Terms of Use for all Users</h1>
          <p className="tailwnd-p tailwnd-f-18 ms-4 pt-2">
            You will not use the Services in a manner that may violate any
            applicable laws, rules or regulations.
          </p>
          <p className="tailwnd-p tailwnd-f-18 ms-4">
            You understand that only teachers, staff members, students and
            parents who are current members of the Subscriber are allowed access
            to the Services. You undertake that you will not allow any form of
            unauthorised access to any personnel that are not affiliated with
            the Subscriber. You will not transfer or give access of your account
            to anyone without the written consent of the Service Provider.
          </p>
          <p className="tailwnd-p tailwnd-f-18 ms-4">
            You undertake that in case of termination of your association with
            the Subscriber, you will cease to access the Services through your
            school’s credentials.
          </p>
          <p className="tailwnd-p tailwnd-f-18 ms-4">
            You undertake that you will not help reproduce, duplicate, reverse
            engineer or otherwise attempt to obtain the source code of any
            features of the Service without Service Provider’s written consent.
          </p>
          <p className="tailwnd-p tailwnd-f-18 ms-4">
            You undertake that you will not share or facilitate unauthorised
            access to any document or other medium containing Confidential
            Information or Intellectual Property Rights of the Service Provider.
          </p>
          <p className="tailwnd-p tailwnd-f-18 ms-4">
            You undertake to use the Service for personal, non- commercial,
            academic purposes only.
          </p>
          <p className="tailwnd-p tailwnd-f-18 ms-4">
            You understand and agree that under no circumstances will Service
            Provider be liable in any way for any content, including, but not
            limited to, any errors or omissions in any content, or any loss or
            damage of any kind incurred in connection with use of or exposure to
            any content posted, emailed, accessed, transmitted, or otherwise
            made available via the Services.
          </p>
          <p className="tailwnd-p tailwnd-f-18 ms-4">
            You warrant, represent and agree that you will not contribute any
            content or otherwise use the Service in a manner that (i) infringes
            or violates the intellectual property rights or proprietary rights,
            or other rights of any third party; (ii) is harmful, fraudulent,
            threatening, abusive, harassing, tortuous, defamatory, vulgar,
            obscene, libellous, or otherwise objectionable; or (iii) jeopardizes
            the security of your account in any way, such as allowing someone
            else access to your account or password.
          </p>
          <p className="tailwnd-p tailwnd-f-18 ms-4">
            You are responsible for all of your activity in connection with the
            Services. Any fraudulent, abusive, or otherwise illegal activity may
            be grounds for termination of your right to access or use the
            Services.
          </p>
          <p className="tailwnd-p tailwnd-f-18 ms-4">
            You undertake to not post or transmit, or cause to be posted or
            transmitted, any communication or solicitation designed or intended
            to obtain password, account, or private information from any User.
          </p>
          <p className="tailwnd-p tailwnd-f-18 ms-4">
            You undertake to not use any manual or automated software, devices,
            or other processes to “crawl” or “spider” any page of the Service
            Provider.
          </p>
        </section>
      </div>
      </div>
      <Footer/>
    </div>
  );
}

export default TermsToUse;
