import './App.css';
import Landing from './component/Home/landing';
import LoginLayout from './component/loginLayout/loginLayout';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import PrivacyPolicy from './component/PrivacyPolicy';
import Pricing from './component/Pricing';
import AboutUs from './component/AboutUs';
import TermsToUse from './component/TermsUse';
import TermsOfService from './component/TermsService';
import CookiesPolicy from './component/CookiesPolicy';
import Dpdpa from './component/Dpdpa';
import ContactUs from './component/ContactUs';
import CancellationPolicy from './component/CancellationPolicy';

function App() {

  return (
    <>
      <BrowserRouter>
        <Routes >
          <Route path="/" element={<Landing />} />
          <Route path="/login" element={<LoginLayout />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/terms-to-use" element={<TermsToUse />} />
          <Route path="/terms-of-service" element={<TermsOfService />} />
          <Route path="/cookies-policy" element={<CookiesPolicy />} />
          <Route path="/dpdpa" element={<Dpdpa />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/refund-cancellation-policy" element={<CancellationPolicy />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
