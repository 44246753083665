import footerlogo from "../../website/images/footerlogo.png";
function Footer() {
  return (
    <>
      <div class="footer">
        <div class="container">
          <div class="footer-content">
            <div class="column">
              <img width="auto" height="auto" src={footerlogo} alt="" />
            </div>
            <div class="column">
              <p>
                Copyright © {new Date().getFullYear()}. All rights reserved.
              </p>
            </div>
            <div class="column d-flex flex-column">
              <a href="/pricing">Pricing</a>
              <a href="/refund-cancellation-policy">Refund & Cancellation </a>
              <a href="/about-us">About us</a>
              <a href="/contact-us">Contact us</a>
              
            </div>
            <div class="column d-flex flex-column ">
              <a href="/privacy-policy">Privacy policy</a>
              <a href="/cookies-policy">Cookies policy</a>
              <a href="/terms-of-service">Terms of service </a>
              <a href="/terms-to-use">Terms to use </a>
              <a href="/dpdpa">DPDPA</a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Footer;
